import axios from 'axios';
import env from "react-dotenv";

const axiosInstance = axios.create({
    baseURL: env.BASE_URL_UI,
    timeout: 10000,
    headers: { 'Authorization': `Bearer ${env.USER_TOKEN_UI}` },
});

export default axiosInstance;
