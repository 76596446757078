import AppRouter from "./AppRouter";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { useEffect, useRef, useState } from "react";
import axiosInstance from "./service/axiosInstance";
import env from "react-dotenv";

function App() {
  const [minHeight, setMinHeight] = useState(0);
  const headerRef = useRef(null);
  const footerRef = useRef(null);

  const [socialIcons, setSocialIcons] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [footerLink, setFooterLink] = useState([]);
  const [logoImg, setLogo] = useState({});


  useEffect(() => {
    const fetchThemeConf = async () => {
        try {
            const customThemeRes = await axiosInstance.get(`sso-custom-page-masters?populate=*&filters[Client_ID]=${env.KEYCLOAK_CLIENT_ID}`);
            
            setSocialIcons([]);
            setHeaders([]);
            setFooterLink([]);
            
            let headerL = [], footerT = [], footerL = [];
            customThemeRes.data.data.forEach((theme) => {
                if (theme.attributes.Section === 'Footer' && theme.attributes.Type === 'Image') {
                    setSocialIcons(prevData => [...prevData, theme.attributes]);
                }
                
                if (theme.attributes.Section === 'Footer' && !theme.attributes.Title.includes('Follow us on') && theme.attributes.Type !== 'Image') {
                    footerL.push(theme.attributes);
                }

                if (theme.attributes.Section === 'Footer' && theme.attributes.Type === 'Text' && !theme.attributes.Title.includes('Follow us on')) {
                  footerT.push(theme.attributes);
                }

                if (theme.attributes.Section === 'Header') {
                  headerL.push(theme.attributes);
                }

                if (theme.attributes.Section === 'Login' && theme.attributes.Type === 'Logo') {
                  setLogo({ Image_Path: theme.attributes.Image_Path, alt: theme.attributes.Type });
                }
            });

            headerL.sort((a, b) => a.Sequence - b.Sequence);
            setHeaders(headerL);

            footerL.sort((a, b) => a.Sequence - b.Sequence);
            setFooterLink(footerL);

        } catch (e) {
            console.error("customThemeRes", e);
        }
        
      };

      fetchThemeConf();
  }, []);

  useEffect(() => {
    function handleResize() {
      if (headerRef.current && footerRef.current) {
        setMinHeight(window.innerHeight - (headerRef.current.offsetHeight + footerRef.current.offsetHeight));
      } else if (footerRef.current) {
        setMinHeight(window.innerHeight - footerRef.current.offsetHeight);
      } else {
        setMinHeight(window.innerHeight);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Header ref={headerRef} headers={headers} logoImg={logoImg} />
      <main style={{ minHeight: `${minHeight}px` }} className='position-relative'>
        <AppRouter />
      </main>
      <Footer ref={footerRef} footerLink={footerLink}  socialIcons={socialIcons} />
    </>
  );
}

export default App;
