import React, { forwardRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { Container } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
// import styles from '../../styles/footer.module.scss';

const Footer = forwardRef((props, ref) => {
  const socialList = [
    {
      icon: `${process.env.PUBLIC_URL}/assets/images/icons/linkedin.svg`,
      link: 'https://www.linkedin.com/company/groupampersand/',
      name: 'linkedin'
    },
    {
      icon: `${process.env.PUBLIC_URL}/assets/images/icons/facebook.svg`,
      link: 'https://www.facebook.com/groupampersand/',
      name: 'facebook'
    },
    {
      icon: `${process.env.PUBLIC_URL}/assets/images/icons/instagram.svg`,
      link: 'https://www.instagram.com/group_ampersand/',
      name: 'instagram'
    },
    {
      icon: `${process.env.PUBLIC_URL}/assets/images/icons/twitter.svg`,
      link: 'https://twitter.com/group_ampersand',
      name: 'twitter'
    },
    {
      icon: `${process.env.PUBLIC_URL}/assets/images/icons/youtube.svg`,
      link: 'https://www.youtube.com/@AmpersandGroup2017',
      name: 'youtube'
    },
  ];
  // const currentYear = new Date().getFullYear();

  const [windowSize, setWindowSize] = useState({
    width: window.outerWidth,
    height: window.outerHeight,
  });

  const [windowSizeInner, setWindowSizeInner] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      setWindowSize({
        width: window.outerWidth,
        height: window.outerHeight,
      });

      setWindowSizeInner({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <footer className="bg-white" ref={ref} style={{ width: '100%', position: 'relative', bottom: '0', left: '0', right: '0', zIndex: '1', minHeight: '100px' }}>
      <div className={`container-fluid hf-text p-2 ${windowSize.width >= 1232 && windowSizeInner.width >= 992 ? "" : "justify-content-center"}`} key={3}>
        <div className="row w-100">
            {props.socialIcons.length > 0 && (
                <div className="col-lg" key={props.socialIcons.length}> 
                    <span style={{display: 'flex', justifyContent: 'center', gap: '10px', fontSize: '15px', color: '#000000', fontWeight: 'bold'}} key={1}>
                        Follow us on
                        <ul className="socialLink mb-0">
                            {props.socialIcons?.map((item, index) => {
                              let temp = socialList.filter((i) => i.name === item.Title)[0];
                              if ( temp.name === item.Title) {
                                return (
                                  <li key={index}>
                                    <Link to={item.link} target="_blank">
                                      <ReactSVG src={temp.icon} />
                                    </Link>
                                  </li>
                                )
                              }
                              return item;
                            })}
                            {/* {props.socialIcons.map((icon, index) => {
                                const imageURL = iconMapping[icon.Title.toLowerCase()];
                                return (
                                    <li key={index}>
                                        <a href={icon.URL} target="_blank" rel="noreferrer" key={index} className="a-text" style={{color: '#666464'}}>
                                            <img src={imageURL} alt={icon.Title} key={index}/>
                                        </a>
                                    </li>
                                );
                            })} */}
                        </ul>
                    </span>
                </div>
            )}
            <div  key={2} style={ windowSize.width >= 1232 && windowSizeInner.width >= 992 ? {display: 'flex', justifyContent: 'center', fontSize: '16px'} : { fontSize: '16px'}}>
                {props.footerLink.map((f, index) => {
                    return (
                      <div key={index} className="col-lg" style={{textAlign: 'center'}}>
                          {f.Type === 'Link' && (
                              <a href={f.URL} key={index} target="_blank" rel="noreferrer" style={{fontSize: '15px', color: '#000000', fontWeight: 'bold'}} className="a-text">{f.Title}</a>
                          )}
                          {f.Type === 'Text' && (
                              <p style={{fontSize: '15px', color: '#000000', fontWeight: 'bold'}} key={index}>{f.Title}</p>
                          )}
                      </div>
                    )
                })}
            </div>
            
        </div>
    </div>
        {/* <Container>
          <div className="py-2 py-md-3 py-lg-4 border-bottom border-light text-center">
            <span className="d-block fs-7 fw-semibold text-primary mb-2">
              Contact Support
            </span>
            <p className="fw-semibold">
              Vector Link: <a href="https://vector.vibgyorhigh.com/" target="_blank" rel="noreferrer" style={{color: '#000000'}}>https://vector.vibgyorhigh.com</a> | +91 600 3000 700
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-column flex-md-row gap-3 py-3 py-md-4 text-center text-md-start">
            <p className="text-gray fs-8 mb-0">
            © {currentYear} Ampersand Group. All Rights Reserved.
            </p>
            <div className="d-flex align-items-center gap-3">
              <span className="text-gray fs-8">Follow us on:</span>
              <ul className={`${styles.amp_social_list}`}>
                {socialList?.map((item, index) => (
                  <li key={index}>
                    <Link to={item.link} target="_blank">
                      <ReactSVG src={item.icon} />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Container> */}
      </footer>
    </>
  );
});

export default Footer;
