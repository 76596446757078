module.exports = [
  //   {
  //     logo: `${process.env.PUBLIC_URL}/assets/images/logos/people_strong.svg`,
  //     linkUrl: "https://ampersand.uat.peoplestrong.com/",
  //   },
    {
      logo: `${process.env.PUBLIC_URL}/assets/images/logos/oracle.svg`,
      linkUrl: "https://login-ewzj-dev1-saasfaprod1.fa.ocs.oraclecloud.com/",
      height: '30px',
    },
    {
      logo: `${process.env.PUBLIC_URL}/assets/images/logos/hubble_star.svg`,
      linkUrl: "https://admin-sss-dev.web.app/#/vgos-internal",
      height: '42px',
    },
    {
      text: "HRMS Onboarding",
      logo: `${process.env.PUBLIC_URL}/assets/images/logos/HH-icon.svg`,
      linkUrl: "https://hrmsonboarding-uat.ampersandgroup.in/",
      height: '55px',
    },
    {
      text: "Hubbleorion ERP",
      logo: `${process.env.PUBLIC_URL}/assets/images/logos/HH-icon.svg`,
      linkUrl: "https://hubbleorion-erp.hubblehox.com/",
      height: '55px',
    },
    // {
    //   logo: `${process.env.PUBLIC_URL}/assets/images/logos/student_kare.svg`,
    //   linkUrl: "/",
    // },
    // {
    //   logo: `${process.env.PUBLIC_URL}/assets/images/logos/elevate.svg`,
    //   linkUrl: "/",
    // },
  ];