import React, { forwardRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from '../../styles/header.module.scss';
import keycloakService from "../../modules";
import { ReactSVG } from 'react-svg';

const Header = forwardRef((props, ref) => {

  const [windowSize, setWindowSize] = useState({
      width: window.outerWidth,
      height: window.outerHeight,
    });
  
    const [windowSizeInner, setWindowSizeInner] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  
    useEffect(() => {
      // Handler to call on window resize
      const handleResize = () => {
        setWindowSize({
          width: window.outerWidth,
          height: window.outerHeight,
        });
  
        setWindowSizeInner({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
  
      // Add event listener
      window.addEventListener("resize", handleResize);
  
      // Clean up the event listener on component unmount
      return () => window.removeEventListener("resize", handleResize);
    }, []);


  return (
    <>
      <header className="" ref={ref}>
      {/* py-2 py-md-3 */}
      {props.headers.length > 0 && 
        <div className={`container-fluid align-items-center justify-content-center gap-3 bg-white pt-3 ${windowSize.width >= 1232 && windowSizeInner.width >= 992 ? "" : "justify-content-center"}`} >
          {/* {JSON.stringify(props.headers)} */}
          <div className="row w-100">
            {props.headers.map((f, index) => {
              if (f.Type === 'Link') {
                  return (
                      <div key={index} className="col-lg" style={{textAlign: 'center'}}>
                          <a href={f.URL} key={index} target="_blank" rel="noreferrer" style={{fontSize: '15px', color: '#000000', fontWeight: 'bold'}} >{f.Title}</a>
                      </div>
                  )
              }
              if(f.Type === 'Text') {
                  return (
                      <div className="col-lg" style={{textAlign: 'center'}}>
                          <p style={{fontSize: '15px', color: '#000000', fontWeight: 'bold'}} key={index}>{f.Title}</p>
                      </div>
                  )
              }
              return f;
              
            })}
          </div>
        </div> 
      }
        <Container className="d-flex align-items-center justify-content-between gap-3 mt-3">
          <Link to={'/'}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} alt="Logo" className={styles.amp_logo} style={{width: '120px'}} />
          </Link>
          <div className='d-flex align-items-center justify-content-end gap-2 gap-md-3 mb-4 mb-md-5'>
            <span className='text-primary fw-light fs-6'>Hi, <strong className='fw-medium'>{keycloakService.getUserInfo('given_name')}</strong> </span>

            <OverlayTrigger placement='bottom-end' overlay={<Tooltip>Sign out</Tooltip>}>
              <Button onClick={() => keycloakService.doLogout()} type='button' variant='primary' className='amp_logout_btn'>
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/logout.svg`} />
              </Button>
            </OverlayTrigger>
          </div>
        </Container>
      </header>
    </>
  );
});

export default Header;
